<template>
  <div>
    <el-card>
      <div class="桃花庵">程序员</div>
      <div class="程序员">写字楼里写字间，写字间中程序员；</div>
      <div class="程序员">程序人员写程序，又将程序换酒钱；</div>
      <div class="程序员">酒醒只在屏前坐，酒醉还来屏下眠；</div>
      <div class="程序员">酒醉酒醒日复日，屏前屏下年复年；</div>
      <div class="程序员">但愿老死电脑间，不愿鞠躬老板前；</div>
      <div class="程序员">奔驰宝马贵者趣，公交自行程序员；</div>
      <div class="程序员">别人笑我太疯癫，我笑自己命太贱；</div>
      <div class="程序员">但见满街漂亮妹，哪个归得程序员；</div>
      <div class="程序员">半人半我半自在，半醒半醉半神仙；</div>
      <div class="程序员">半亲半爱半苦乐，半俗半禅半随缘。</div>
    </el-card>
    <el-card class="box-card-message">
      <div style="background-color: #fcfcfc;">
        <el-row>
          <el-col :span="24" style="text-align: left;font-weight: bold;margin-bottom: 5px"><div>留言记录</div></el-col>
        </el-row>
        <div v-for="(item,index) in replylist" style="text-align: left;font-size: 20px">
          <div>{{index+1}}L</div>
          <div class="el-icon-s-custom" style="margin: 20px;" v-if="item.Createdate"> {{item.Nickname}} 评论时间 {{item.Createdate.replace('T',' ')}}</div>
          <div style="margin: 20px 30px;font-size: 16px">{{item.Content}}</div>
          <div style="margin: 20px;" v-if="item.Reply"> 博主 回复  {{item.Replydate.replace('T',' ')}}</div>
          <div style="margin: 20px 30px;font-size: 16px" v-if="item.Reply">{{item.Reply}}</div>
          <el-divider></el-divider>
        </div>
      </div>
    </el-card>
    <el-card class="box-card">
      <div style="background-color: #fcfcfc;">
        <el-row>
          <el-col :span="24" style="text-align: left;font-weight: bold;"><div>发表留言</div></el-col>
        </el-row>
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
          <el-form-item label="昵称" prop="nickname">
            <el-input v-model="addForm.nickname" placeholder="请输入昵称"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="addForm.email" placeholder="请输入正确的邮箱，作者将会回复，邮箱接收消息"></el-input>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <el-input v-model="addForm.content" type="textarea" placeholder="请输入内容" rows="3"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="onsubmitreply">提交</el-button>
        <el-button type="primary" @click="onreply">重置</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {AddBlogReply, GetBlogReplyDetal, GetBlogReplyMessage} from "@/network/blog";

export default {
  name: "Lmessage",
  data(){
    return{
      addForm:{
        nickname:'',
        email:'',
        content:'',
      },
      //新增用户字段验证规则
      addFormRules:{
        nickname: [
          { required: true, message: '请输入昵称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
          { min: 3, max: 512, message: '长度在 3 到 512 个字', trigger: 'blur' }
        ]
      },
      replylist:[],
    }
  },
  methods:{
    onreply(){
      this.$refs.addFormRef.resetFields()
    },
    onsubmitreply(){
      this.$refs.addFormRef.validate(call=>{
        if (!call)
          return
        AddBlogReply(this.addForm).then(res => {
          this.initreplylist()
          this.$message({
            message: '留言成功！',
            type: 'success'
          })
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //加留言
    initreplylist() {
      GetBlogReplyMessage().then(res => {
        this.replylist =res.data
      }).catch(err => {
        console.log(err);
      })
    }
  },created() {
    this.initreplylist()
  }
}
</script>

<style scoped>
.桃花庵 {
  margin: 20px;
}
.程序员 {
  margin: 10px;
}
.box-card-message{
  margin-top: 20px;
  margin-bottom: 20px;
}
.box-card{

}
</style>
